<template>
  <div id="app">
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
      <a href class="navbar-brand" @click.prevent>Frate Planning <span style="font-size: 0.7em;">v{{appVersion}}</span></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav mr-auto">
          <!-- <li class="nav-item">
          <router-link to="/home" class="nav-link">
            <font-awesome-icon icon="home" />Accueil
          </router-link>
        </li>
        <li v-if="showAdminBoard" class="nav-item">
          <router-link to="/admin" class="nav-link">Admin Board</router-link>
        </li>
        <li v-if="showModeratorBoard" class="nav-item">
          <router-link to="/mod" class="nav-link">Moderator Board</router-link>
        </li> -->
          <li v-if="showFrateUser" class="nav-item">
            <router-link v-if="currentUser" to="/user" class="nav-link"
              ><font-awesome-icon icon="calendar-alt" />Planning</router-link
            >
          </li>
          <li class="nav-item">
            <router-link v-if="showExterneUser || showFrateUser" to="/pointage" class="nav-link"
              ><font-awesome-icon icon="user-check" />Pointage</router-link
            >
          </li>
          <li class="nav-item">
            <router-link v-if="showFrateUser" to="/decompte" class="nav-link"
              ><font-awesome-icon icon="clock" />Suivi des heures</router-link
            >
          </li>
          <li class="nav-item">
            <router-link v-if="showFrateUser" to="/questionnaire" class="nav-link"
              ><font-awesome-icon icon="question" />Questionnaire</router-link
            >
          </li>
        </ul>

        <ul v-if="!currentUser" class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link to="/register" class="nav-link">
              <font-awesome-icon icon="user-plus" />S'inscrire
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              <font-awesome-icon icon="sign-in-alt" />Se connecter
            </router-link>
          </li>
        </ul>

        <ul v-if="currentUser" class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link to="/profile" class="nav-link">
              <font-awesome-icon icon="user" />
              {{ currentUser.firstName }} {{ currentUser.lastName }} -
              {{ currentUser.username }}
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href @click.prevent="logOut">
              <font-awesome-icon icon="sign-out-alt" />Se déconnecter
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <div class="container-xl">
      <router-view />
    </div>

    <div class="update-dialog" v-if="prompt">
      <div class="update-dialog__content">
        Une nouvelle version est disponible. Mettre à jour pour actualiser ?
      </div>
      <div class="update-dialog__actions">
        <button
          class="update-dialog__button update-dialog__button--confirm"
          @click="update"
        >
          Mettre à jour
        </button>
        <button
          class="update-dialog__button update-dialog__button--cancel"
          @click="prompt = false"
        >
          Annuler
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import {version} from '../package'

export default {
  name: "app",
  data() {
    return {
      prompt: false,
      appVersion:version
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showExterneUser() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_EXTERNE");
      }
      return false;
    },
    showFrateUser() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_FRATE");
      }
      return false;
    },
    // showAdminBoard() {
    //   if (this.currentUser && this.currentUser.roles) {
    //     return this.currentUser.roles.includes("ROLE_ADMIN");
    //   }

    //   return false;
    // },
    // showModeratorBoard() {
    //   if (this.currentUser && this.currentUser.roles) {
    //     return this.currentUser.roles.includes("ROLE_MODERATOR");
    //   }

    //   return false;
    // },
  },
  methods: {
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.prompt = true;
      });
    }
  },
};
</script>

<style lang="scss">
.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 64px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 576px;
  color: white;
  background-color: #2c3e50;
  text-align: left;
  &__actions {
    display: flex;
    margin-top: 8px;
  }
  &__button {
    margin-right: 8px;
    color: white;
    background-color: #435261;
    &--confirm {
      margin-left: auto;
    }
  }
}
</style>
