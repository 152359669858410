<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
      <form name="form" @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="username">Matricule (<b>laissez vide</b> si vous êtes externe à Frate Formation)</label>
          <input
            v-model="user.username"
            type="text"
            class="form-control"
            name="username"
            id="username"
            autocomplete="on"
          />
          <div
            v-if="errors.has('username')"
            class="alert alert-danger"
            role="alert"
          >Utilisateur is required!</div>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            v-model="user.email"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="email"
            id="email"
            autocomplete="email"
          />
          <div
            v-if="errors.has('email')"
            class="alert alert-danger"
            role="alert"
          >L'email est obligatoire!</div>
        </div>
        <div class="form-group">
          <label for="password">Mot de passe</label>
          <input
            v-model="user.password"
            v-validate="'required'"
            type="password"
            class="form-control"
            name="password"
            id="password"
            autocomplete="current-password"
          />
          <div
            v-if="errors.has('password')"
            class="alert alert-danger"
            role="alert"
          >Le mot de passe est obligatoire!</div>
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>Se connecter</span>
          </button>
        </div>
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert"><span v-html="message"></span></div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
    import User from '../models/user';

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', '', '', ''),
      loading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/user');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.email && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              if (this.currentUser.roles.includes("ROLE_FRATE")) {
                this.$router.push('/user');
              } else {
                this.$router.push('/pointage');
              }
            },
            error => {
              // this.message =
              //   (error.response && error.response.data) ||
              //   error.message ||
              //   error.toString();
              this.message = "Vérifiez vos identifiants et mot de passe ! <br /><span style=\"color:black;font-size: 0.75em;\">*Dans le cas d'une nouvelle inscription ou d'une modification de mot de passe, avant de pouvoir vous connecter il faut la valider en cliquant le lien contenu dans le dernier email reçu (si besoin vérifiez vos spam).</span>";
              this.loading = false;
            }
          );
        }
      });
    }
  }
};
</script>
   
<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>