<template>
  <div class="col-md-12">
    <div class="card card-container">
      <span class="titre">Complétez ce formulaire pour vous inscrire ou pour modifier votre mot de passe</span>
      
      <form name="form" @submit.prevent="handleRegister">
        <div v-if="!successful">
          <div class="form-group">
            <label for="username"
              >Matricule (<b>laissez vide</b> si vous êtes externe à Frate
              Formation) :</label
            >
            <input
              v-model="user.username"
              v-validate="'min:1|max:10'"
              type="text"
              class="form-control"
              name="username"
            />
            <div
              v-if="submitted && errors.has('username')"
              class="alert-danger"
            >
              {{ errors.first("username") }}
            </div>
          </div>
          <div class="form-group">
            <label for="email">Votre adresse email référencée chez Frate Formation :</label>
            <input
              v-model="user.email"
              v-validate="'required|email|max:250'"
              type="email"
              class="form-control"
              name="email"
            />
            <div v-if="submitted && errors.has('email')" class="alert-danger">
              {{ errors.first("email") }}
            </div>
          </div>
          <div class="form-group">
            <label for="password">Choisissez un mot de passe d'au moins 6 caractères :</label>
            <input
              v-model="user.password"
              v-validate="'required|min:6|max:20'"
              type="password"
              class="form-control"
              name="password"
            />
            <div
              v-if="submitted && errors.has('password')"
              class="alert-danger"
            >
              {{ errors.first("password") }}
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
              S'inscrire
            </button>
          </div>
        </div>
      </form>

      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import User from "../models/user";

export default {
  name: "register",
  data() {
    return {
      user: new User("", "", "", ""),
      submitted: false,
      successful: false,
      message: "",
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleRegister() {
      this.loading = true;
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.$store.dispatch("auth/register", this.user).then(
            (data) => {
              this.message = data.message;
              if (data.uid === false) {
                this.successful = false;
                this.loading = false;
              } else {
                this.successful = true;
              }
            },
            (error) => {
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
              this.successful = false;
              this.loading = false;
            }
          );
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 12px;
  font-size: 12px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.titre {
  font-size: 19px;
  text-align: center;
  margin: 0;
  padding: 24px 0;
  color: #00539f;
  font-weight: bold;
}
</style>
