import UserService from '../services/user.service';

const events = JSON.parse(localStorage.getItem('events'));
const initialState = events
  ? { status: { cachedIn: true }, events }
  : { status: { cachedIn: false }, events: null };

export const user = {
  namespaced: true,
  state: initialState,
  actions: {
    getEventData({ commit }) {
      return UserService.getUserBoard().then(
        events => {
          commit('getEventDataSuccess', events);
          return Promise.resolve(events);
        },
        error => {
          commit('getEventDataFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    getEventDataSuccess(state, events) {
      state.status.cachedIn = true;
      state.events = events;
    },
    getEventDataFailure(state) {
      state.status.cachedIn = false;
      state.events = null;
    }
  }
};