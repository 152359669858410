import Vue from "vue";

// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";


import VueFilterDateFormat from "vue-filter-date-format";
Vue.use(VueFilterDateFormat);
import VeeValidate from "vee-validate";

import App from "./App.vue";
import store from "./store";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faUserCheck,
  faCalendarAlt,
  faUserSlash,
  faClock,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faUserCheck,
  faCalendarAlt,
  faUserSlash,
  faClock,
  faQuestion,
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);
Vue.use(VeeValidate);

// Vue.use(VueCharts);

import router from './router'

import wb from "./registerServiceWorker";

Vue.prototype.$workbox = wb;

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register", "/home"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  // if (authRequired && !loggedIn) {
  if (authRequired && !loggedIn) {
    if (to.path.indexOf("/questionnaireExt/")!= -1 ) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

window.jQuery = require('jquery');
window.$ = window.jQuery;
window.Swal = require('sweetalert2');




require('bootstrap');
require('popper.js');
require('bootstrap/dist/css/bootstrap.css');



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
