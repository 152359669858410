import Vue from 'vue'
import Router from 'vue-router'

// Page content
import Home from "@/components/home.vue";
import Login from "@/components/login.vue";
import Register from "@/components/register.vue";

// Fallback page
import PageNotFound from '@/components/pageNotFound.vue'

Vue.use(Router)

export default new Router({
    routes : [
        { path: '/', redirect: '/user' },
        { path: "/home", component: Home },
        { path: "/login", component: Login },
        { path: "/register", component: Register },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/components/profile.vue"),
        },
        {
          path: "/user",
          name: "user",
          component: () => import("@/components/boardUser.vue"),
        },
        {
          path: "/admin",
          name: "admin",
          component: () => import("@/components/boardAdmin.vue"),
        },
        {
          path: "/mod",
          name: "moderator",
          component: () => import("@/components/boardModerator.vue"),
        },
        {
          path: "/pointage",
          name: "pointage",
          component: () => import("@/components/pointage.vue"),
        },
        {
          path: "/decompte",
          name: "decompte",
          component: () => import("@/components/decompte.vue"),
        },
        {
          path: "/questionnaire",
          name: "questionnaire",
          component: () => import("@/components/questionnaire.vue"),
        },
        {
          path: "/questionnaireExt/:uniqueId",
          name: "questionnaireExt",
          component: () => import("@/components/questionnaireExt.vue"),
        },
        {
          path: "/questionnaireExt/",
          name: "questionnaireExt2",
          component: () => import("@/components/questionnaireExt.vue"),
          // component: PageNotFound,
        },
        {
            path: '**',
            name: 'PageNotFound',
            component: PageNotFound
          }
      ]


})

