import axios from "axios";
import authHeader from "./auth-header";

const API_URL = 'https://frate-planning.aelia-informatique.fr:28044/api/v1/board/';
// const API_URL = 'http://192.168.0.59/api/v1/board/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserBoard() {
    return axios
      .get(API_URL + "user", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.events.length > 0) {
          const events = JSON.parse(localStorage.getItem("events"));
          const initialEvents = events ? events : null;

          if (initialEvents) {
            response.data.events.forEach((element) => {
              const i = initialEvents.findIndex((e) => e.id == element.id);
              if (i != -1) initialEvents.splice(i, 1, element);
              else initialEvents.push(element);
            });
            localStorage.setItem("events", JSON.stringify(initialEvents));
          } else {
            localStorage.setItem(
              "events",
              JSON.stringify(response.data.events)
            );
            return response.data.events;
          }
        }

        return response.data.events;
      });
  }

  getFicheAnalytique(params) {
    return axios.post(
      API_URL + "ficheanalytique",
      {
        data: params,
      },
      {
        headers: authHeader(),
      }
    );
  }

  getDecompteHeures(params) {
    return axios.post(
      API_URL + "decompteheures",
      {
        data: params,
      },
      {
        headers: authHeader(),
      }
    );
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }
}

export default new UserService();
